const initFeedbackSlider = () => {
  const breakpointLg = window.matchMedia('(max-width: 1023px)');

  const sliderWrapper = document.querySelector('.service-feedback__slider');

  let swiper;

  if (!sliderWrapper) {
    return;
  }

  const sliderInit = (slider) => {
    swiper = new Swiper(slider, {
      slidesPerView: 'auto',
      slidesPerGroup: 1,
    });
  };

  const breakpointChecker = () => {
    if (breakpointLg.matches) {
      sliderInit(sliderWrapper);
    } else {
      if (swiper) {
        swiper.destroy(true, true);
      }
    }
  };
  breakpointLg.addListener(breakpointChecker);
  breakpointChecker();
};

export {initFeedbackSlider};
