const initFeatures = () => {
  const features = document.querySelectorAll('.features__item');

  if (!features) {
    return;
  }

  const breakpoint = window.matchMedia('(min-width: 1024px)');
  let activeElem = null;
  let timer = null;

  function setupFeatures(items) {
    items.forEach((item) => {
      const content = item.querySelector('.features__more');
      if (breakpoint.matches) {
        item.addEventListener('mouseenter', () => {
          if (breakpoint.matches) {
            activeElem = item;
            content.style.maxHeight = content.scrollHeight + 'px';
            content.classList.add('active');
            item.addEventListener('mouseleave', () => {
              if (activeElem) {
                activeElem = null;
              }
              setTimeout(() => {
                if (activeElem !== item) {
                  content.style.maxHeight = null;
                  content.classList.remove('active');
                }
              }, 500);
            }, {once: true});
          }
        });
      } else {
        content.style.maxHeight = '';
      }
    });
  }

  setupFeatures(features);

  window.addEventListener('resize', () => {
    setupFeatures(features);
  });

  window.addEventListener('scroll', () => {
    if (breakpoint.matches) {
      clearTimeout(timer);
      features.forEach((item) => {
        item.style.pointerEvents = 'none';
      });
      timer = setTimeout(function () {
        features.forEach((item) => {
          item.style.pointerEvents = '';
        });
      }, 400);
    }
  });
};

export {initFeatures};
