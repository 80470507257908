const initCanvas = () => {
  const canvasBlock = document.querySelector('.js-canvas');

  if (!canvasBlock) {
    return;
  }

  const canvasWrap = canvasBlock.closest('.js-canvas-wrap');
  const ctx = canvasBlock.getContext('2d');

  const breakpointMd = window.matchMedia('(min-width:1024px)');
  const sequencePath = canvasBlock.dataset['path'];
  const fileType = canvasBlock.dataset['fileType'];
  const isFullWidth = canvasBlock.hasAttribute('data-is-full-width');
  // const sequenceStart = 1;
  const sequenceEnd = canvasBlock.dataset['totalFrames'];

  let canvasWidth;
  let canvasHeight;
  let windowWidth;
  let images = [];
  let currentIndex = 0;

  const setWindowSize = () => {
    if (isFullWidth) {
      canvasBlock.width = window.innerWidth;
      canvasBlock.height = window.innerHeight;
      canvasWidth = window.innerWidth;
      canvasHeight = window.innerHeight;
      windowWidth = window.innerWidth;
    } else {
      canvasBlock.width = canvasWrap.getBoundingClientRect().width;
      canvasBlock.height = canvasWrap.getBoundingClientRect().height;
      canvasWidth = canvasWrap.getBoundingClientRect().width;
      canvasHeight = canvasWrap.getBoundingClientRect().height;
      windowWidth = window.innerWidth;
    }

  };

  setWindowSize();

  const createImage = (index) => {
    const image = new Image();
    image.src = sequencePath + index + '.' + fileType;

    if (index === 1) {
      image.addEventListener('load', () => {
        ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
      });
      // image.onload = function () {
      //   ctx.drawImage(image, 0, 0);
      // };
    }

    return image;
  };

  if (breakpointMd.matches) {
    for (let i = 1; i <= sequenceEnd; i++) {
      const img = createImage(i);
      images.push(img);
    }
  } else {
    for (let i = 1; i <= 1; i++) {
      const img = createImage(i);
      images.push(img);
    }
  }

  const drawActualImage = () => {
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx.drawImage(images[currentIndex], 0, 0, canvasWidth, canvasHeight);
    // window.requestAnimationFrame(drawActualImage);
  };

  // drawActualImage();

  const getCurrentIndex = (currentMousePosition) => {
    const maxIndex = sequenceEnd - 1;
    currentIndex = Math.round((currentMousePosition * maxIndex) / windowWidth);
    return currentIndex;
  };

  window.addEventListener('load', () => {
    window.addEventListener('mousemove', (evt) => {
      if (breakpointMd.matches) {
        let x = evt.clientX;
        getCurrentIndex(x);
        drawActualImage();
      }
    });

    window.addEventListener('resize', () => {
      setWindowSize();
      drawActualImage();
    });

    window.addEventListener('orientationchange', () => {
      setWindowSize();
      drawActualImage();
    });

    // window.requestAnimationFrame(drawActualImage);
  });

};

export {initCanvas};
