import enableInlineVideo from '../vendor/iphone-inline-video.es-modules';

const initVideo = () => {
  const video = document.querySelectorAll('video[autoplay]');

  if (!video.length) {
    return;
  }

  video.forEach((el) => {
    enableInlineVideo(el);
  });
};

export {initVideo};
