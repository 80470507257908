import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/init-modals';
import {initServices} from './modules/services-list';

import {initInputWrappers} from './modules/init-input-wrapper';
import {initAutoresizeTextarea} from './modules/autoresize-textarea';
import {initRequestValidation} from './modules/init-request-validation';
import {initParallax} from './modules/parallax';
import {initFeatures} from './modules/init-features';
import {initAos} from './modules/aos-init';
import {initSlider} from './modules/init-media-slider';
import {initFeedbackSlider} from './modules/init-feedback-slider';
import {initVideo} from './modules/enable-inline-video';
import {telMask} from './modules/init-mask-tel';
// import {initMousemoveVideo} from './modules/init-mousemove-video';
import {initCanvas} from './modules/init-canvas';


// Utils

// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

initModals();
initServices();
initInputWrappers();
initAutoresizeTextarea();
initRequestValidation();
initParallax();
initFeatures();
initAos();
initParallax();
initSlider();
initFeedbackSlider();
initVideo();
telMask();
// initMousemoveVideo();
initCanvas();
