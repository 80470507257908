const initRequestValidation = () => {
  const TEL_MIN_LENGTH = 16;
  const mailRegEx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const nameRegEx = /[^А-Я,а-я,a-z,A-Z,ё,Ё,' ',-]/;

  const form = document.querySelector('.feedback-form form');
  const formSubmitBtn = document.querySelector('.feedback-form button[type="submit"]');
  const nameInput = document.querySelector('.input-wrapper input[name="name"]');
  const organisationInput = document.querySelector('.input-wrapper input[name="organisation"]');
  const telInput = document.querySelector('.input-wrapper input[type="tel"]');
  const emailInput = document.querySelector('.input-wrapper input[type="email"]');

  if (form) {
    const formInputs = form.querySelectorAll('.input-wrapper:not(.js-tel):not(.input-wrapper--email) input:required');
    form.setAttribute('novalidate', '');

    form.addEventListener('input', () => {
      if (form.checkValidity() && telInput.value.length === TEL_MIN_LENGTH) {
        form.classList.add('form-valid');
      } else {
        form.classList.remove('form-valid');
      }
    });

    nameInput.addEventListener('input', () => {
      nameInput.value = nameInput.value.replace(nameRegEx, '');
    });


    formSubmitBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      document.activeElement.blur();
      formInputs.forEach((el) => {
        if (!el.checkValidity()) {
          el.closest('.input-wrapper').classList.add('input-wrapper--error');
          el.closest('.input-wrapper').classList.remove('input-wrapper--valid');
        }
      });

      if (telInput.value.length < TEL_MIN_LENGTH) {
        telInput.closest('.input-wrapper').classList.add('input-wrapper--error');
        telInput.closest('.input-wrapper').classList.remove('input-wrapper--valid');
      }

      if (!mailRegEx.test(emailInput.value)) {
        emailInput.closest('.input-wrapper').classList.add('input-wrapper--error');
        emailInput.closest('.input-wrapper').classList.remove('input-wrapper--valid');
      }

      telInput.addEventListener('input', () => {
        const value = telInput.value;

        if (value.length === TEL_MIN_LENGTH) {
          if (telInput.closest('.input-wrapper').classList.contains('input-wrapper--error')) {
            telInput.closest('.input-wrapper').classList.remove('input-wrapper--error');
          }
          telInput.closest('.input-wrapper').classList.add('input-wrapper--valid');
        } else {
          telInput.closest('.input-wrapper').classList.remove('input-wrapper--valid');
        }
      });

      emailInput.addEventListener('input', () => {
        if (mailRegEx.test(emailInput.value)) {
          if (emailInput.closest('.input-wrapper').classList.contains('input-wrapper--error')) {
            emailInput.closest('.input-wrapper').classList.remove('input-wrapper--error');
          }
          emailInput.closest('.input-wrapper').classList.add('input-wrapper--valid');
        } else {
          emailInput.closest('.input-wrapper').classList.remove('input-wrapper--valid');
        }
      });
      nameInput.addEventListener('input', () => {
        if (nameInput.value) {
          if (nameInput.closest('.input-wrapper').classList.contains('input-wrapper--error')) {
            nameInput.closest('.input-wrapper').classList.remove('input-wrapper--error');
          }
          nameInput.closest('.input-wrapper').classList.add('input-wrapper--valid');
        } else {
          nameInput.closest('.input-wrapper').classList.remove('input-wrapper--valid');
        }
      });
      organisationInput.addEventListener('input', () => {
        if (organisationInput.value) {
          if (organisationInput.closest('.input-wrapper').classList.contains('input-wrapper--error')) {
            organisationInput.closest('.input-wrapper').classList.remove('input-wrapper--error');
          }
          organisationInput.closest('.input-wrapper').classList.add('input-wrapper--valid');
        } else {
          organisationInput.closest('.input-wrapper').classList.remove('input-wrapper--valid');
        }
      });

      if (formSubmitBtn.closest('form').classList.contains('form-valid')) {
        var form = formSubmitBtn.closest('form');
        fetch(form.action, {method:'post', body: new FormData(form)})
            .then(response => {
              return response.json();
            })
            .then(data => {
              if (data.success == 'ok') {
                var htmlObject = document.createElement('div');
                htmlObject.innerHTML = data.message;
                form.replaceWith(htmlObject);
              }
            });
      }
    });
  }
};

export {initRequestValidation};

