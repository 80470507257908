const inputWrappers = document.querySelectorAll('.input-wrapper');
const initInputWrappers = () => {
  inputWrappers.forEach((el) => {
    const input = el.querySelector('input');
    const textarea = el.querySelector('textarea');
    const requiredInput = el.querySelector('input:required');
    el.addEventListener('input', () => {
      if (input) {
        if (input.value) {
          el.classList.add('input-wrapper--has-value');
        } else {
          el.classList.remove('input-wrapper--has-value');
        }

        if (requiredInput) {
          if (requiredInput.checkValidity()) {
            el.classList.add('input-wrapper--valid');
          } else {
            el.classList.remove('input-wrapper--valid');
          }
        }
      } else {
        if (textarea.value) {
          el.classList.add('input-wrapper--has-value');
        } else {
          el.classList.remove('input-wrapper--has-value');
        }
      }
    });
  });
};

export {initInputWrappers};
