import {setupModal} from '../utils/modal';
import {setServiceListHeight} from './services-list';

const modals = document.querySelectorAll('.modal');
const modalServices = document.querySelector('.modal--services');
const modalServicesBtns = document.querySelectorAll('[data-modal="services"]');

const makeHeaderBlack = function (btn) {
  const modal = this;
  const header = document.querySelector('header');
  header.classList.add('header--black');
  btn.innerText = window.innerWidth > 374 ? modalServicesBtns[0].dataset.textOpen : modalServicesBtns[0].dataset.textOpenMobile;
  const serviceList = modal.querySelector('.services-list');
  if (serviceList) {
    setServiceListHeight(serviceList);
  }
};

const makeHeaderWhite = function (btn) {
  const header = document.querySelector('header');
  header.classList.remove('header--black');
  btn.innerText = modalServicesBtns[0].dataset.textClose;
};

// аргументы setupModal(modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock)
// возможна инициализация только с первыми аргументом,
// если вам нужно открывать модалку в другом месте под какими-нибудь условиями
const initModals = () => {
  // фикс для редких случаев, когда модалка появляется при загрузке страницы
  window.addEventListener('load', () => {
    if (modals.length) {
      modals.forEach((el) => {
        setTimeout(() => {
          el.classList.remove('modal--preload');
        }, 100);
      });
    }
  });

  if (modalServices && modalServicesBtns.length) {
    setupModal(modalServices, makeHeaderWhite.bind(null, modalServicesBtns[0]), modalServicesBtns, makeHeaderBlack.bind(modalServices, modalServicesBtns[0]), false, true);
  }
};

export {initModals};
