const openModal = (modal, callback, preventScrollLock) => {
  modal.classList.add('modal--active');

  if (callback) {
    callback();
  }

  if (!preventScrollLock) {
    window.disableBodyScroll(modal, {
      reserveScrollBarGap: true,
    });
  }
};

const closeModal = (modal, callback, preventScrollLock) => {
  modal.classList.remove('modal--active');

  if (callback) {
    callback();
  }

  if (!preventScrollLock) {
    setTimeout(() => {
      window.enableBodyScroll(modal);
    }, 300);
  }
};

const onEscPress = (evt, modal, callback) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && modal.classList.contains('modal--active')) {
    evt.preventDefault();
    closeModal(modal, callback);
  }
};

const setModalListeners = (modal, closeCallback, preventScrollLock) => {
  const overlay = modal.querySelector('.modal__overlay');
  const closeBtn = modal.querySelector('.modal__close-btn');

  closeBtn.addEventListener('click', () => {
    closeModal(modal, closeCallback, preventScrollLock);
  });

  overlay.addEventListener('click', () => {
    closeModal(modal, closeCallback, preventScrollLock);
  });

  document.addEventListener('keydown', (evt) => {
    onEscPress(evt, modal, closeCallback, preventScrollLock);
  });
};

const setupModal = (modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock) => {
  if (modalBtns) {

    modalBtns.forEach((btn) => {
      btn.addEventListener('click', (evt) => {
        if (!noPrevDefault) {
          btn.blur();
          evt.preventDefault();
        }
        if (!modal.classList.contains('modal--active')) {
          openModal(modal, openCallback, preventScrollLock);
        } else {
          closeModal(modal, closeCallback, preventScrollLock);
        }
        if (preventScrollLock) {
          const documentClickHandler = ({target}) => {
            if (!target.closest('.modal__content') && !target.closest('.header')) {
              closeModal(modal, closeCallback, preventScrollLock);
              document.removeEventListener('click', documentClickHandler);
            }
          };
          setTimeout(() => {
            if (modal.classList.contains('modal--active')) {
              document.addEventListener('click', documentClickHandler);
            }
          }, 100);
        }
      });
    });
  }

  setModalListeners(modal, closeCallback, preventScrollLock);
};

export {setupModal, openModal, closeModal};
