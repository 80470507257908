function setServiceListHeight(list) {
  list.style.minHeight = 'auto';
  setTimeout(() => {
    list.style.minHeight = list.scrollHeight + 'px';
  }, 500);
}

const initServices = () => {
  const servicesLists = document.querySelectorAll('.services-list');

  if (!servicesLists.length) {
    return;
  }

  const breakpointLg = window.matchMedia('(min-width: 1024px)');
  let margin = 32;

  function setServiceLinks(links, activeItem, width) {
    let activeItemWidth = width * 2;

    links.forEach((link, index) => {
      const parent = link.closest('.services-list__item');
      if (breakpointLg.matches) {
        if (index === 0) {
          activeItem = parent;
          parent.classList.add('services-list__item--active');
          parent.style.width = 'calc(' + activeItemWidth + '% - ' + margin + 'px';
        } else {
          parent.classList.remove('services-list__item--active');
          parent.style.width = 'calc(' + width + '% - ' + margin + 'px';
        }
        link.addEventListener('mouseenter', (evt) => {
          if (breakpointLg.matches) {
            evt.preventDefault();
            setTimeout(() => {
              activeItem.classList.remove('services-list__item--active');
              activeItem.style.width = 'calc(' + width + '% - ' + margin + 'px';
              parent.classList.add('services-list__item--active');
              parent.style.width = 'calc(' + activeItemWidth + '% - ' + margin + 'px';
              activeItem = parent;
            }, 200);
          }
        });
      } else {
        parent.style.width = '';
      }
    });
  }

  function setServiceList(lists) {
    lists.forEach((list) => {
      setServiceListHeight(list);
      const servicesLinks = list.querySelectorAll('.services-list__link');
      let activeServiceItem = list.querySelector('.services-list__item--active');
      let itemWidth = servicesLinks.length <= 4 ? 100 / (servicesLinks.length + 1) : 20;
      setServiceLinks(servicesLinks, activeServiceItem, itemWidth);
    });
  }

  setServiceList(servicesLists);

  window.addEventListener('resize', () => {
    setServiceList(servicesLists);
  });

  window.addEventListener('orientationchange', () => {
    setTimeout(() => {
      setServiceList(servicesLists);
    }, 500);
  });
};

export {initServices, setServiceListHeight};
