import SimpleParallax from '../vendor/simpleParallax';

const initParallax = () => {
  const sliderImage = document.querySelectorAll('.parallax-wrapper img, .parallax-wrapper video');

  if (!sliderImage) {
    return;
  }

  const breakpoint = window.matchMedia('(min-width: 1024px)');
  let instance = null;

  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      sliderImage.forEach((item) => {
        const parent = item.closest('.parallax-wrapper');
        if (parent) {
          parent.classList.add('parallax-wrapper--active');
        }
      });
    }, 700);

  });

  function setupParallax() {
    if (breakpoint.matches) {
      if (!instance) {
        instance = new SimpleParallax(sliderImage, {
          orientation: 'down',
          scale: '1.2',
          customWrapper: '.parallax-wrapper',
        });
      }
    } else {
      if (instance) {
        instance.destroy();
        instance = null;
      }
    }
  }

  setupParallax();

  window.addEventListener('resize', () => {
    setupParallax();
  });
};

export {initParallax};
