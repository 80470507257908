const initSlider = () => {

  const initSliderAction = () => {
    const slider = document.querySelector('.service-media__content');

    if (!slider) {
      return;
    }
    const sliderButtons = slider.querySelectorAll('.service-slider__button');
    const slides = slider.querySelectorAll('.swiper-slide');
    const speed = slider.dataset.speed;
    let isLoop;
    if (slider.dataset.loop) {
      isLoop = Boolean(slider.dataset.loop)
    }
    if (slides.length > 1) {
      //eslint-disable-next-line no-undef
      const swiper = new Swiper(slider, {
        speed: speed ? parseInt(speed) : 400,
        navigation: {
          nextEl: '.service-slider__button--next',
          prevEl: '.service-slider__button--prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        loop: isLoop,
        on: {
          slideChange: function() {
            const activeIndex = swiper.realIndex;
            if (activeIndex > 1) {
              const currentSlide = slides[activeIndex - 1];

              const video = currentSlide.querySelector('video');
              if (video) {
                video.pause();
              } else {
                const iframe = currentSlide.querySelector('iframe');
              if (iframe) {

                iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
              }
              }
            } else {
              const currentSlide = slides[activeIndex];
              const video = currentSlide.querySelector('video');
              if (video) {
                video.pause();
              } else {
                const iframe = currentSlide.querySelector('iframe');
                if (iframe) {
                  iframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                }
              }
            }
          },
        },
      });
    } else {
      sliderButtons.forEach((el) => {
        el.style.display = 'none';
      });
    }
  };
  initSliderAction();

};

export {initSlider};
