/*! npm.im/intervalometer */
function intervalometer(cb, request, cancel, requestParameter) {
  var requestId;
	var previousLoopTime;
	function loop(now) {
		// Must be requested before cb() because that might call .stop()
		requestId = request(loop, requestParameter);

		// Called with "ms since last call". 0 on start()
		cb(now - (previousLoopTime || now));

		previousLoopTime = now;
	}

	return {
		start: function start() {
			if (!requestId) { // Prevent double starts
				loop(0);
			}
		},
		stop: function stop() {
			cancel(requestId);
			requestId = null;
			previousLoopTime = 0;
		}
	};
}

function frameIntervalometer(cb) {
	return intervalometer(cb, requestAnimationFrame, cancelAnimationFrame);
}

function timerIntervalometer(cb, delay) {
	return intervalometer(cb, setTimeout, clearTimeout, delay);
}

export { intervalometer, frameIntervalometer, timerIntervalometer };